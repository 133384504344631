<template>
  <div>
    <NModal
    v-model:show="modalShow"
    preset="card"
    size="small"
    title="随机组卷"
    :segmented="{ content: 'hard', footer: 'hard' }"
    style="width: 800px;"
    :mask-closable="false"
  >
    <template #default>
      <div>
        <LabelList
          style="overflow-y:auto;"
          ref="labelListRef"
          label-list-title="标签列表"
          :label-type="resourceEnum.EXERCISES_RESOURCE_TYPE"
          show-no-label
          @list-update="handlePageChange"
          :label-content-height="labelContentHeight"
        />
      </div>
      <div id="question-type">
        <p class="question-type-text">题型数量</p>
        <NForm
          ref="formRef"
          label-placement="left"
          :label-width="88"
          require-mark-placement="left"
          :model="formValue"
        >
        <div v-for="item in formValue" :key="item.type">
          <NFormItem :label="item.name" :path="item.typee">
          <NInputNumber
            style="width: 250px;"
            :max="item.max"
            :min="0"
            clearable
            v-model:value="item.num"
          />
          <span style="margin-left:30px">总题数：{{item.number}}</span>
          <span style="margin-left:30px" v-if="item.number - item.selected">已选：{{item.number - item.selected}}</span>
          <span style="margin-left:30px" v-if="item.number - item.selected >0">剩余总题数：{{item.selected}}</span>
          </NFormItem>
      </div>
      </NForm>
      </div>
    </template>
    <template #footer>
      <NSpace justify="end">
        <NButton @click="closeModal">取消</NButton>
        <NButton type="primary" @click="confirmModal">确定</NButton>
      </NSpace>
    </template>
  </NModal>

  <PageLoading :loading="loading" />
</div>
</template>

<script setup>
  import { h, ref, reactive, nextTick } from 'vue';
  import { NSpace, NButton, NTime, useMessage, useDialog } from 'naive-ui';
  import { useRouter } from 'vue-router';
  
  import PageLoading from '@/components/PageLoading/index.vue';
  import LabelList from '@/components/LabelList/LabelList.vue';

  import { getExerciseList, PostExerciseList} from '@/api/exercises.js';
  import { getToken } from '@/utils/auth.js';
  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import { toRaw } from '@vue/reactivity'
  
  const router = useRouter();
  const loading = ref(false);
  const message = useMessage();
  const formRef = ref(null)

  const props = defineProps({
    labelContentHeight: {
      type: String,
      default: 'auto'
    },
    // 所有选择的ID
    exerciseIdAll: {
      type: Array,
      default: '[]'
    }
  });
  const exerciseIdAll = props.exerciseIdAll
  
  const labelListRef = ref(null);
  const getSelectedExerciseIds = () => ''

  const modalShow = ref(false);
  const openModalRandom = (args) => {
    modalShow.value = true;
    formValue.value.forEach(item=>{item.num = 0})
    nextTick(() => {
      QuestionTypeNumber('')
    });
  };

  const emit = defineEmits(['exercise-id-add','exercise-id-all']);
  // (1单选，2多选，3判断 4开放 5填空)
  const formValue = ref([
    {
    type:1,
    name:'单选：',
    typee:'dx',
    num:0,        // 当前填写数量
    selected:0,   // 剩余数量
    max: 0,
    number:0      // 总数量
    },
    {
    type:2,
    name:'多选：',
    typee:'dxx',
    num:0,        // 当前填写数量
    selected:0,   // 剩余数量
    max: 0,
    number:0      // 总数量
    },
    {
    type:3,
    name:'判断：',
    typee:'pd',
    num:0,        // 当前填写数量
    selected:0,   // 剩余数量
    max: 0,
    number:0      // 总数量
    },
    {
    type:4,
    name:'开放：',
    typee:'kf',
    num:0,        // 当前填写数量
    selected:0,   // 剩余数量
    max: 0,
    number:0      // 总数量
    },
    {
    type:5,
    name:'填空：',
    typee:'tk',
    num:0,        // 当前填写数量
    selected:0,   // 剩余数量
    max: 0,
    number:0      // 总数量
    }
  ])

  formValue.value.forEach(item=>{
    item.num = 0
  })
  const label = ref('')
 
  const handlePageChange = res=>{
    label.value = res.selectedIdsArr.toString()
    QuestionTypeNumber(label.value)
  }
  
  function QuestionTypeNumber(params) {
    let data = {
    'random_type':'1',
    'Exercises[labels]':params,
    'random_number':1,
    'not_in_exercises_ids':exerciseIdAll.toString()   // 已选的ID集
    }
    getExerciseList(data).then(ress=>{
      
      if(ress.code==1){
        ress.data.forEach(item=>{
        // (1单选，2多选，3判断 4开放 5填空)
        if(item.type=='1'){
          formValue.value[0].number = Number(item.num)
          formValue.value[0].selected = Number(item.end_num)
          formValue.value[0].max = Number(item.end_num) || Number(item.num) || 0;
        }
        else if(item.type=='2'){
          formValue.value[1].number = Number(item.num)
          formValue.value[1].selected = Number(item.end_num)
          formValue.value[1].max = Number(item.end_num) || Number(item.num) || 0;
        }
        else if(item.type=='3'){
          formValue.value[2].number = Number(item.num)
          formValue.value[2].selected = Number(item.end_num)
          formValue.value[2].max = Number(item.end_num) || Number(item.num) || 0;
        }
        else if(item.type=='4'){
          formValue.value[3].number = Number(item.num)
          formValue.value[3].selected = Number(item.end_num)
          formValue.value[3].max = Number(item.end_num) || Number(item.num) || 0;
        }
        else if(item.type=='5'){
          formValue.value[4].number = Number(item.num)
          formValue.value[4].selected = Number(item.end_num)
          formValue.value[4].max = Number(item.end_num) || Number(item.num) || 0;
        }
      })
      }else{
        formValue.value.forEach((item,index)=>{
          formValue.value[index].number = 0
          formValue.value[index].selected = 0
        })
      }

    })
    .catch(err=>{

    })
  }

  const confirmModal = () => {
    formRef.value.validate().then( errMsg => {
      if (!errMsg){
        let IsNum = 0
          formValue.value.forEach(item=>{
            IsNum+=Number(item.num)
          })
          if(IsNum){
            let reqData = []
            reqData = toRaw(formValue.value)
              
            let data = {
              'random_type':'1',
              'Exercises[labels]':label.value,
              'random_list':reqData,
              'not_in_exercises_ids':exerciseIdAll.toString()   // 已选的ID集
            }
            loading.value = true;
            PostExerciseList(data).then(res=>{
              // console.log('res',res);
              let arrID = []
              res.data.forEach(item=>{
                arrID.push(item.id)
                exerciseIdAll.push(item.id)
              })
              // 已选ID存入临时存储
              
              loading.value = false;
              emit('exercise-id-all', exerciseIdAll);
              emit('exercise-id-add', arrID);
              closeModal();
            })
            .catch(err=>{
            
            })
          }else{
            message.error('数量不能为空');
          }
      }
    }, rule => {});
  };
  
  const closeModal = () => {
    modalShow.value = false;
  };
  defineExpose({
    getSelectedExerciseIds,
    openModalRandom,
    closeModal
  });
</script>
<style code>
.question-type-text{
  font-size: 16px;
  margin-left: 15px;
  margin-bottom: 15px;
}
</style>